import {validateKeysSyncing} from "../utils";

const lc_en = {
    links__resource_dqw_intro_vid: "https://player.vimeo.com/video/207126525?h=35667144b6&title=0&byline=0&app_id=122963",
    links__resource_guide_pdf: "https://storage.googleapis.com/izhero-public/lessons/5af15636ed6ea426eebefe17-0._Getting_Started_and_Registering_Classes.pdf",
    links__resource_stm_pdf: "https://storage.googleapis.com/izhero-public/lessons/5af151b0add1d84859275a22-1._ScreenTime_Management.pdf",
    links__resource_stm_ppt: "https://storage.googleapis.com/izhero-public/lessons/5af151b0add1d84859275a22-DQWorld_Intro_Slides_Zone_1.pptx",
    links__resource_pm_pdf: "https://storage.googleapis.com/izhero-public/lessons/587597e102b425515b9f9733-2._Privacy_Management.pdf",
    links__resource_cbm_pdf: "https://storage.googleapis.com/izhero-public/lessons/5875980e02b425515b9f9734-3._Cyber-Bullying_Management.pdf",
    links__resource_dci_pdf: "https://storage.googleapis.com/izhero-public/lessons/587599f402b425515b9f9735-4._Digital_Citizen_Identity.pdf",
    links__resource_dfm_pdf: "https://storage.googleapis.com/izhero-public/lessons/58759a1e02b425515b9f9736-5._Digital_Footprint_Management.pdf",
    links__resource_csm_pdf: "https://storage.googleapis.com/izhero-public/lessons/58759a9a02b425515b9f9737-6._Cyber_Security_Management.pdf",
    links__resource_ct_pdf: "https://storage.googleapis.com/izhero-public/lessons/58759ae502b425515b9f9738-7._Critical_Thinking.pdf",
    links__resource_de_pdf: "https://storage.googleapis.com/izhero-public/lessons/58759b1702b425515b9f9739-8._Digital_Empathy.pdf",
}

const lc_ko = {

}

const lc_th = {

}

const lc_ja = {
    links__resource_dqw_intro_vid: "https://player.vimeo.com/video/306148197?title=0&byline=0&app_id=122963",
    links__resource_guide_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/0._Getting_Strated_ja.pdf",
    links__resource_stm_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/1._Screentime_Management_ja.pdf",
    links__resource_pm_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/2._Privacy_Management_ja.pdf",
    links__resource_cbm_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/3._Cyber-Bullying_Management_ja.pdf",
    links__resource_dci_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/4._Digital_Citizen_Identity_ja.pdf",
    links__resource_dfm_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/5._Digital_Footprint_Management_ja.pdf",
    links__resource_csm_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/6._Cyber_Security_Management_ja.pdf",
    links__resource_ct_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/7._Critical_Thinking_ja.pdf",
    links__resource_de_pdf: "https://storage.googleapis.com/izhero-public/lessons/ja/8._Digital_Empathy_ja.pdf",
}

export const LC_VALUES_LINKS = {
    'en': lc_en,
    'ko': lc_ko,
    'th': lc_th,
    'ja': lc_ja
}

validateKeysSyncing(LC_VALUES_LINKS, 'links');